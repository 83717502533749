import React, {
  memo,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import qs from 'query-string';
import { useFormik } from 'formik';

import Button from '../UI/Button';
import Chosen from '../UI/Chosen';
import InputText from '../UI/InputText';

const buildPath = (lang, type, slugs, query) => {
  let path = (lang === 'en') ? '' : `/${lang}`;
  const isSalePage = type === 'sale';

  if (lang === 'fr') {
    path += isSalePage ? '/vente' : '/location';
  } else {
    path += isSalePage ? '/sale' : '/rental';
  }

  if (slugs.property_type) {
    path += `/${slugs.property_type}`;
  }

  if (slugs.city) {
    path += `/${slugs.city}`;
  }

  return qs.stringifyUrl({
    url: `${path}/`,
    query
  }, {
    skipNull: true,
    skipEmptyString: true
  });
};

const buildQuery = (selected, options) => {
  const query = {
    ...selected
  };

  const slugs = {
    city: null,
    property_type: null
  };

  if (!query.sector.length && query.city.length === 1) {
    const option = options.city.find((c) => c.value === query.city[0]);

    if (option) {
      slugs.city = option.slug;
      query.city = [];
    }
  }

  if (query.property_type.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    slugs.property_type = query.property_type[0];
    query.property_type = [];
  }

  return { query, slugs };
};

const initialState = {
  sector: [],
  city: [],
  rental_type: [],
  property_type: [],
  bedrooms: [],
  budget: [],
  ref: ''
};

const Filters = ({
  lang,
  content = {},
  options = [],
  selected = {},
  type
}) => {
  const {
    values,
    resetForm,
    handleSubmit,
    setFieldValue,
    setValues
  } = useFormik({
    initialValues: initialState,
    onSubmit: (form) => {
      const { slugs, query } = buildQuery(form, options);
      const path = buildPath(lang, type, slugs, query);
      window.location.href = window.location.origin + path;
    }
  });

  const onMultipleChange = useCallback((name, value) => {
    const prevSelected = values[name];

    const items = (prevSelected.indexOf(value) === -1)
      ? [...prevSelected, value]
      : prevSelected.filter((item) => item !== value);

    return setFieldValue(name, items);
  }, [
    values,
    setFieldValue
  ]);

  const onSectorChange = useCallback((value) => {
    onMultipleChange('sector', value);

    setFieldValue('city', []);
  }, [
    setFieldValue,
    onMultipleChange
  ]);

  const citiesOptions = useMemo(() => {
    const { sector } = values;

    if (!sector.length) {
      return options.city;
    }

    const selectedSectors = options.sector.filter((s) => sector.indexOf(s.value) !== -1);
    const cities = selectedSectors.reduce((acc, cur) => [...acc, ...cur.relation], []);

    return options.city.filter((c) => cities.indexOf(c.value) !== -1);
  }, [
    options,
    values
  ]);

  const onReset = useCallback(() => {
    resetForm(initialState);
  }, [resetForm]);

  useEffect(() => {
    setValues(Object.keys(initialState).reduce((prev, key) => ({
      ...prev,
      [key]: selected[key] || initialState[key]
    }), {}));
  }, [
    selected,
    setValues
  ]);

  const colClassName = 'col-lg col-md-3 col-sm-4 col-xs-12';

  return (
    <form onSubmit={handleSubmit}>
      <div className={cn('container', 'filter-wrap', `filter-${type}`)}>
        <div className="row middle-lg">
          <div className={cn(colClassName, 'sector')}>
            <Chosen
              multiple
              maxItems={99}
              content={content.sector}
              options={options.sector}
              selected={values.sector}
              onChange={onSectorChange}
            />
          </div>

          <div className={cn(colClassName, 'city')}>
            <Chosen
              multiple
              searchable
              maxItems={7}
              content={content.city}
              options={citiesOptions}
              selected={values.city}
              onChange={(value) => onMultipleChange('city', value)}
            />
          </div>

          {(type === 'rental') && (
            <div className={cn(colClassName, 'rental_type')}>
              <Chosen
                content={content.rental_type}
                options={options.rental_type}
                selected={values.rental_type}
                onChange={(value) => setFieldValue('rental_type', [value])}
              />
            </div>
          )}

          <div className={cn(colClassName, 'property_type')}>
            <Chosen
              multiple
              content={content.property_type}
              options={options.property_type}
              selected={values.property_type}
              onChange={(value) => onMultipleChange('property_type', value)}
            />
          </div>

          <div className={cn(colClassName, 'bedrooms')}>
            <Chosen
              content={content.bedrooms}
              options={options.bedrooms}
              selected={values.bedrooms}
              onChange={(value) => setFieldValue('bedrooms', [value])}
            />
          </div>

          {(type === 'sale') && (
            <div className={cn(colClassName, 'budget')}>
              <Chosen
                multiple
                content={content.budget}
                options={options.budget}
                selected={values.budget}
                onChange={(value) => onMultipleChange('budget', value)}
              />
            </div>
          )}

          <div className={cn(colClassName, 'reference')}>
            <InputText
              name="ref"
              placeholder={content.ref.placeholder}
              type="text"
              value={values.ref}
              onChange={({ target }) => setFieldValue('ref', target.value)}
            />
          </div>

          <div className={cn(colClassName, 'actions')}>
            <Button
              className="search gold-solid"
              type="submit"
            >
              {content.search}
            </Button>

            <Button
              className="reset"
              title={content.reset}
              onClick={onReset}
            >
              {content.reset}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

Filters.propTypes = {
  lang: PropTypes.string.isRequired,
  content: PropTypes.object,
  options: PropTypes.object,
  selected: PropTypes.object,
  type: PropTypes.oneOf(['sale', 'rental']).isRequired
};

export default memo(Filters);
