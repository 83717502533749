import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import scroll from 'scroll';
import { Scrollbars } from 'rc-scrollbars';

import { capitalize, getWidth, noop } from '../../../utils';
import Button from '../Button';

const keys = {
  ENTER: 13,
  UP: 38,
  DOWN: 40,
  ESC: 27
};

const ROW_HEIGHT = 35;
const TABLET_MAX_WIDTH = 1024;

const Thumb = ({
  style = {},
  ...props
}) => (
  <div
    style={{
      ...style,
      backgroundColor: '#808080',
      width: 4,
      borderRadius: 2,
      cursor: 'pointer'
    }}
    {...props}
  />
);

Thumb.propTypes = {
  style: PropTypes.object
};

const initialState = {
  focused: 0,
  opened: false,
  query: '',
  scrollable: false
};

const Chosen = ({
  maxItems = 6,
  content = {},
  options = [],
  selected = [],
  multiple = false,
  searchable = false,
  onChange = noop
}) => {
  const [{
    focused,
    opened,
    query,
    scrollable
  }, setState] = useState({ ...initialState });

  const buttonRef = useRef();
  const containerRef = useRef();
  const inputRef = useRef();
  const optionsRef = useRef();

  const title = useMemo(() => {
    if (!selected.length) {
      return content.placeholder;
    }

    if (!multiple) {
      return options.find((option) => option.value === selected[0]).name;
    }

    if (selected.length > 2 && content.multiple) {
      return `${selected.length} ${content.multiple}`;
    }

    return options
      .filter((o) => selected.indexOf(o.value) !== -1)
      .map((option) => capitalize(option.name)).join(', ');
  }, [
    content,
    options,
    multiple,
    selected
  ]);

  const items = useMemo(() => {
    const queryUpper = query.toLowerCase();

    if (!queryUpper.trim()) {
      return options;
    }

    return options.filter((option) => {
      const name = option.name.toLowerCase();
      return name.indexOf(queryUpper) !== -1;
    });
  }, [
    query,
    options
  ]);

  const onQueryChange = useCallback(({ target }) => setState((prevState) => ({
    ...prevState,
    query: target.value,
    focused: 0
  })), []);

  const onMouseMove = useCallback((index) => setState((prevState) => {
    if (prevState.focused === index) {
      return prevState;
    }

    return {
      ...prevState,
      focused: index
    };
  }), []);

  const onOpen = useCallback(() => setState((prevState) => {
    if (prevState.opened) {
      return prevState;
    }

    return {
      ...prevState,
      opened: true,
      focused: 0
    };
  }), []);

  const onClose = useCallback(() => setState((prevState) => {
    if (!prevState.opened) {
      return prevState;
    }

    return {
      ...prevState,
      opened: false,
      query: ''
    };
  }), []);

  const onWindowClick = useCallback(({ target }) => {
    if (document.body.contains(target) && !containerRef.current.contains(target)) {
      onClose();
    }
  }, [onClose]);

  const onSelect = useCallback((value) => {
    onChange(value);

    if (!multiple) {
      onClose();
    }
  }, [
    multiple,
    onChange,
    onClose
  ]);

  const selectFocused = useCallback(() => {
    if (typeof focused === 'number') {
      const option = items[focused];

      if (option) {
        onSelect(option.value);
      }
    }
  }, [
    focused,
    items,
    onSelect
  ]);

  const onWindowKeyDown = useCallback((event) => {
    const { keyCode } = event;

    if (keyCode === keys.ESC) {
      onClose();
      return;
    }

    if (keyCode === keys.ENTER) {
      event.stopPropagation();
      event.preventDefault();

      selectFocused();
      return;
    }

    if (keyCode === keys.UP) {
      event.stopPropagation();
      event.preventDefault();

      setState((prevState) => ({
        ...prevState,
        focused: (prevState.focused === 0)
          ? (items.length || 1) - 1
          : prevState.focused - 1,
        scrollable: true
      }));
    }

    if (keyCode === keys.DOWN) {
      event.stopPropagation();
      event.preventDefault();

      setState((prevState) => ({
        ...prevState,
        focused: (prevState.focused === items.length - 1)
          ? 0
          : prevState.focused + 1,
        scrollable: true
      }));
    }
  }, [
    items,
    onClose,
    selectFocused
  ]);

  useEffect(() => {
    if (!opened || !scrollable || (typeof focused !== 'number')) {
      return;
    }

    const elements = optionsRef.current.view.querySelectorAll('button');

    if (elements[focused]) {
      const height = elements[focused].clientHeight;
      const offset = elements[focused].offsetTop - height * 2;
      scroll.top(optionsRef.current.view, offset, { duration: 100 });
    }

    setState((prevState) => ({
      ...prevState,
      scrollable: false
    }));
  }, [
    focused,
    opened,
    scrollable
  ]);

  useEffect(() => {
    if (opened && searchable && (getWidth() > TABLET_MAX_WIDTH)) {
      inputRef.current.focus();
    }

    if (!opened) {
      buttonRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  useEffect(() => {
    if (opened) {
      window.addEventListener('keydown', onWindowKeyDown);
    }

    return () => {
      if (opened) {
        window.removeEventListener('keydown', onWindowKeyDown);
      }
    };
  }, [
    opened,
    onWindowKeyDown
  ]);

  useEffect(() => {
    document.addEventListener('chosen-close', onClose);

    return () => {
      document.removeEventListener('chosen-close', onClose);
    };
  }, [
    onClose
  ]);

  useEffect(() => {
    document.addEventListener('click', onWindowClick, true);

    return () => {
      document.removeEventListener('click', onWindowClick, true);
    };
  }, [
    onWindowClick
  ]);

  return (
    <div
      ref={containerRef}
      className={cn('chosen', {
        'chosen-selected': !!selected.length,
        'chosen-multiple': multiple,
        'chosen-opened': opened
      })}
    >
      <button
        className="chosen-title"
        ref={buttonRef}
        type="button"
        onClick={opened ? onClose : onOpen}
      >
        {title}
      </button>

      {opened && (
        <div className="chosen-results">
          {searchable && (
            <input
              className={cn('chosen-input', {
                'chosen-input-error': !items.length
              })}
              ref={inputRef}
              type="text"
              value={query}
              onChange={onQueryChange}
            />
          )}

          <Scrollbars
            ref={optionsRef}
            autoHeight
            autoHeightMax={maxItems * ROW_HEIGHT}
            renderThumbVertical={Thumb}
          >
            {items.map((item, index) => (
              <Button
                key={item.value}
                className={cn('chosen-item', {
                  'chosen-selected': selected.indexOf(item.value) !== -1,
                  'chosen-focused': focused === index
                })}
                onClick={() => onSelect(item.value)}
                onMouseMove={() => onMouseMove(index)}
              >
                {item.name}
              </Button>
            ))}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

Chosen.propTypes = {
  maxItems: PropTypes.number,
  content: PropTypes.object,
  options: PropTypes.array,
  selected: PropTypes.array,
  multiple: PropTypes.bool,
  searchable: PropTypes.bool,
  onChange: PropTypes.func
};

export default memo(Chosen);
